exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-content-use-consent-tsx": () => import("./../../../src/pages/content-use-consent.tsx" /* webpackChunkName: "component---src-pages-content-use-consent-tsx" */),
  "component---src-pages-contribute-tsx": () => import("./../../../src/pages/contribute.tsx" /* webpackChunkName: "component---src-pages-contribute-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-login-tsx": () => import("./../../../src/pages/login.tsx" /* webpackChunkName: "component---src-pages-login-tsx" */),
  "component---src-pages-sjt-questionnaire-tsx": () => import("./../../../src/pages/sjt-questionnaire.tsx" /* webpackChunkName: "component---src-pages-sjt-questionnaire-tsx" */),
  "component---src-pages-sjt-tsx": () => import("./../../../src/pages/sjt.tsx" /* webpackChunkName: "component---src-pages-sjt-tsx" */),
  "component---src-templates-material-page-tsx": () => import("./../../../src/templates/material-page.tsx" /* webpackChunkName: "component---src-templates-material-page-tsx" */)
}

