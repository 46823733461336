module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"icon":"src/images/icon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"6d4809f630f7dfcf820ddea5507a107f"},
    },{
      plugin: require('../node_modules/gatsby-plugin-breadcrumb/gatsby-browser.js'),
      options: {"plugins":[],"useAutoGen":true,"autoGenHomeLabel":"Browse Material","exclude":["**/dev-404-page/**","**/404/**","**/404.html"],"excludeOptions":{"separator":"."},"crumbLabelUpdates":[],"trailingSlashes":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-matomo/gatsby-browser.js'),
      options: {"plugins":[],"siteId":"4","matomoUrl":"https://analytics.list.lu","matomoPhpScript":"rainbow","matomoJsScript":"unicorn","siteUrl":"https://browse.fairnessinteaching-project.eu","requireCookieConsent":true,"cookieDomain":"*.fairnessinteaching-project.eu","dev":false},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
